import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import GameSetting from "@/views/newgame/GameSetting";
import PersonalSetting from "@/views/newgame/PersonalSetting";
import Settlement from "@/views/newgame/Settlement";
import account from "@/views/newgame/account";
import OldGameList from "@/views/olcgame/OldGameList";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/game-setting',
    name: 'GameSetting',
    component: GameSetting
  },
  {
    path: '/Settlement',
    name: 'Settlement',
    component: Settlement
  },
  {
    path: '/account',
    name: 'account',
    component: account
  },
  {
    path: '/personal-setting',
    name: 'PersonalSetting',
    component: PersonalSetting
  },
  {
    path: '/old-game-list',
    name: 'OldGameList',
    component: OldGameList
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  base: '/',
  routes: routes
})
router.beforeEach((to,from, next) => {
  document.body.style.backgroundColor = 'rgb(251,238,194,.8)' || to.meta.backgroundColor
  document.title = to.meta.title || ''
  next()
})
export default router
