<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/img/logo.svg">
    <HelloMessage msg="~ 计分小助手 ~"/>

    <div id="nav">
      <van-button class="van-button" type="primary" @click="newGame" >新局</van-button>
      <van-button class="van-button" type="warning" @click="oldGame">旧局</van-button>
    </div>

  </div>
</template>


<style>
  .home {
    margin-top: 60px;
  }
</style>
<script>
// @ is an alias to /src
import HelloMessage from "@/components/HelloMessage";

export default {
  name: 'Home',
  components: {
    HelloMessage
  },
  methods: {
    newGame() {
      this.$router.replace("/game-setting");
    },
    oldGame() {
      this.$router.replace("/old-game-list");
    }
  }
}
</script>
