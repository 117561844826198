<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.van-overflow-hidden {
  overflow: auto !important;
}
#nav {
  padding: 10px;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 60px;
}

.van-button {
  margin: 10px;
}

</style>
