<template>
  <div>
    <van-nav-bar
        title="历史记录"
        left-text="返回"
        left-arrow
        right-text="清空"
        @click-left="onClickLeft"
        @click-right="onCLickRight"
    />

    <div class="history-table">
      <div class="title">记录</div>
      <div >
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <van-cell v-for="(item, index) in gameList" :key="index" :title="`ID: ${item.name} 人数: ${item.numbers || 0} `"
              @click="onCellClick(item.id)"/>
        </van-list>
      </div>
    </div>


  </div>
</template>

<script>

import {Dialog} from "vant";

export default {
  name: "OldGameList",
  methods: {
    onCellClick(id) {
      this.$router.replace({
        name: 'Settlement',
        query: {
          id,
        }
      })
    },
    onClickLeft() {
      this.$router.replace("/");
    },
    onCLickRight() {
      Dialog.confirm({
        title: '警告',
        message: '确认是否清空所有历史记录',
      })
          .then(() => {
            // on confirm
            const sessionKeys = Object.keys(localStorage).filter((key) => {return key.startsWith('ID-')});
            for (let i=0; i<sessionKeys.length; i++) {
              localStorage.removeItem(sessionKeys[i]);
            }
            this.gameList = [];
          })
          .catch(() => {
            // on cancel
          });
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.list = this.gameList;

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= this.gameList.length) {
          this.finished = true;
        }
      }, 1000);
    },
  },
  data() {
    return {
      gameList: [],
      list: [],
      loading: false,
      finished: false,
    }
  },
  created() {
    if (localStorage) {
      const sessionKeys = Object.keys(localStorage).filter((key) => {return key.startsWith('ID-')});

      for (let i=0; i<sessionKeys.length; i++) {
        //可以对key 进行分析从而决定是否要删除sessionStorage 里的缓存
        const clientData = JSON.parse(localStorage.getItem(sessionKeys[i]));
        const gameInfo = clientData.gameInfo;
        this.gameList.push(gameInfo);
      }
      this.gameList.sort((a, b) => b.id - a.id);

    }
  }
}
</script>

<style scoped>
.history-table {
  background: #fff;
  margin: 10px 10px;
  padding: 16px 0;
}

.history-table .title {
  font-size: 20px;
  font-weight: 600;
}

.input-score {
  padding: 10px;
  margin: 0 10px;
  /* width: 80%; */
  border: 1px solid rgb(32, 97, 55);
}

.history-table /deep/ .grid-wrap {
  margin: 8px;
  padding-bottom: 16px;
  border: 2px solid rgb(32, 97, 55);
  background-color: rgb(174, 141, 100) !important
}

.deep-background {
  background-color: rgb(174, 141, 100) !important;
}

.deep-background /deep/ .van-grid-item__content {
  background-color: rgb(174, 141, 100) !important;
  color: #000;
  font-weight: 600;
}
</style>
