<template>
<div id="gameSetting">
  <van-nav-bar
      title="设置"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
  />

  <van-row class="vanRow" type="flex" justify="space-between">
    <van-col span="4"></van-col>
    <van-col span="16">
      <van-field v-model="stepper" type="digit" :label="`玩家人数`" :placeholder="`请输入玩家人数`" />
    </van-col>
    <van-col span="4"></van-col>
  </van-row>

  <van-row class="vanRow" type="flex" justify="space-between">
    <van-col span="4"></van-col>
    <van-col span="16">
      <van-field v-model="gameName" :label="`对局名称`" :placeholder="`请输入对局名称`" />
    </van-col>
    <van-col span="4"></van-col>
  </van-row>

  <van-row class="vanRow" type="flex" justify="space-between">
    <van-col span="24">
      <van-button class="van-button" type="primary" @click="nextStep" >下一步</van-button>
    </van-col>
  </van-row>

</div>
</template>

<script>

import {Dialog} from "vant";

export default {
  name: "GameSetting",
  components: {
  },
  methods: {
    onClickLeft() {
      this.$router.replace("/");
    },
    onClickRight() {

    },
    nextStep() {
      if (this.stepper < 4 || this.stepper > 8) {
        Dialog.alert({
          message: '玩家人数最小为4，最大为8',
        }).then(() => {
          // on close
        });
        return;
      }

      this.$router.replace({
        name: 'PersonalSetting',
        query: {
          players: this.stepper,
          gameName: this.gameName
        }
      });
    }
  },
  data() {
    return {
      stepper: 4,
      gameName: ''
    };
  },
}
</script>

<style scoped>
@import url('../../assets/css/base.css');

#gameSetting{
  text-align: center;
}
</style>
