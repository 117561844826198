<template>
<div>
  <van-nav-bar
      title="玩家设置"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
  />

  <van-row class="vanRow" type="flex" justify="space-between" v-for="(player,n) in players" :key="n">
    <van-col span="4">
    </van-col>
    <van-col span="16">
      <!-- <van-cell-group> -->
        <van-field v-model="player.name" :label="`玩家${n+1}`" :placeholder="`玩家${n+1}`" />
      <!-- </van-cell-group> -->
    </van-col>
    <van-col span="4">
    </van-col>
  </van-row>

  <van-row class="vanRow" type="flex" justify="space-between">
    <van-col span="24">
      <van-button class="van-button" type="primary" @click="nextStep" >下一步</van-button>
    </van-col>
  </van-row>

</div>
</template>

<script>

export default {
  name: "PersonalSetting",
  components: {
  },
  props: {

  },
  data() {
    return {
      players: []
    }
  },
  created() {
    const playerLength = Number(this.$route.query.players) || 4
    this.$set(this, 'players', this.createdPlayers(playerLength, ['name', 'age', 'sex']))
  },
  methods: {
    createdPlayers(number, fieldList) {
      const returnPlayers = []
      for(let i = 0; i < number; i++) {
        const player = {}
        fieldList.forEach(field => {
          player[field] = null
        })
        returnPlayers.push(player)
      }
      return returnPlayers
    },
    onClickLeft() {
      this.$router.replace("game-setting");
    },
    onClickRight() {

    },
    nextStep() {
      const id = String(new Date().getTime())
      const obj = {
        userList: this.players.map((user,i) => {
          user.name = user.name || `玩家${i+1}`
          user.score = user.score || 0
          return user
        }),
        gameInfo: {
          id: id,
          beginTime: id,
          name: this.$route.query.gameName || id,
          numbers: Number(this.$route.query.players)
        }
      }
      localStorage.setItem('ID-'+id, JSON.stringify(obj))

      this.$router.replace({
        name: 'Settlement',
        query: {
          id,
        }
      })
    },
    onSubmit() {

    }
  },
}
</script>

<style scoped>
@import url('../../assets/css/base.css');

</style>
