<template>
  <div class="settlement-wrap">
    <van-nav-bar
      title="游戏中"
      left-text="主页"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="person-list">
      <van-row type="flex"  v-for="(row, rowIndex) in  (Math.ceil(players.length / 4))" :key="rowIndex">
        <van-col span="6" v-for="(player) in players.slice(rowIndex * 4, (rowIndex+1) * 4)" :key="player.name">
          <div class="name">{{player.name}}</div>
          <div class="input-score deep-background">{{player.allScore}}张</div>
        </van-col>
      </van-row>
    </div>
    <div class="history-table" v-if="history.length">
      <div class="title">记录</div>
      <div class="grid-wrap deep-background">
        <van-grid :column-num="1+players.length" :border="true">
          <van-grid-item v-for="(player,pIndex) in players" :key="pIndex"
                         :text="String(player.name)" class="op" />
          <van-grid-item  />
        </van-grid>
        <van-grid :column-num="1+players.length" :border="true">
          <van-grid-item v-for="(player,pIndex) in players" :key="pIndex"
                         :text="'(' + String(player.currentScore | 0) + ')'" class="opb" />
          <van-grid-item  />
        </van-grid>
        <van-grid :column-num="1+histo.userList.length" v-for="(histo,hisIndex) in history" :key="histo.createdTime+hisIndex" :border="true">
          <van-grid-item v-for="(sco,scoIndex) in histo.userList" :key="scoIndex" :text="String(Number(sco.score))" :class="!histo.state ? 'disabled-see': 'see'" />
          <van-grid-item class="op"  text="删除" v-if="histo.state" @click="$set(histo, 'state',0);setAllScore()" />
          <van-grid-item class="op" text="撤销" @click="$set(histo, 'state',1);setAllScore()" v-else/>
        </van-grid>
      </div>
    </div>
    <div class="btn-list">
      <van-row class="vanRow" type="flex" justify="space-around">
        <van-button class="van-button" type="primary" @click="nextStep" >结算</van-button>
        <van-button class="van-button" type="warning" @click="openDialog" >输入</van-button>
    </van-row>
    </div>
    <van-popup transition v-model="showInput" closeable position="bottom" :style="{ height: '100%',background: '#fff' }" close-icon="cross" close-icon-position="top-left" get-container="body" class="input-score-model">
      <div class="row-wrap">
        <van-row type="flex"  v-for="(row, rowIndex) in  (Math.ceil(players.length / 4))" :key="rowIndex" class="input-row">
          <van-col span="6" v-for="(player,playerIndex) in players.slice(rowIndex * 4, (rowIndex+1) * 4)" :key="player.name">
            <div class="name">{{player.name}}</div>
            <van-field type="digit" maxlength="4"
                       input-align center border
                       v-model="newHistory.userList[rowIndex*4+playerIndex].score"></van-field>
          </van-col>
        </van-row>
      </div>
      <div class="btn-list-model">
      <van-row class="vanRow" type="flex" justify="space-around">
        <van-button class="van-button" type="primary" @click="pushNewHistory" >确定</van-button>
        <van-button class="van-button" type="warning" @click="showInput=false" >取消</van-button>
    </van-row>
    </div>
    </van-popup>

    <van-popup transition v-model="rushLine" closeable position="bottom"
               :style="{ height: '100%',background: '#fff' }"
               close-icon="cross" close-icon-position="top-left"
               get-container="body" class="input-score-model">
      <div class="rush-line">
        <img alt="Vue logo" src="../../assets/img/qingzhu.png">
      </div>
      <div class="rush-line-text">
        {{rushUser || ''}} 冲线啦～～～
      </div>

      <div class="btn-list-model">
        <van-row class="vanRow" type="flex" justify="space-around">
          <van-button class="van-button" type="primary" @click="nextStep" >去结算</van-button>
          <van-button class="van-button" type="warning" @click="rushLine =! rushLine" >继续游戏</van-button>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script type="text/ecmascript-6">

export default {
  data() {
    return {
      players: [], // 玩家数组，保存了总成绩
      history: [], // 当前记录
      doneList: [], // 已完成的记录
      showInput: false,
      rushLine: false,
      newHistory: {},
      gameInfo: {},
      rushUser: '',
    }
  },
  components: {

  },
  computed: {

  },
  methods: {
    setAllScore() {
      this.players = this.players.map(item => {
        item.allScore = 0
        return item
      })
      this.history.forEach((item) => {
        !!item.state && item.userList.forEach((person,personIndex) => {
          if(!this.players[personIndex].allScore) {
            this.players[personIndex]['allScore'] = 0
          }
          this.players[personIndex].allScore += Number(person.score)
        })
      })
      this.setSessionData()
    },
    nextStep() {
      this.$router.replace({
        name: 'account',
        query: {
          id:this.$route.query.id
        }
      })
    },
    onClickLeft() {
      this.$router.replace("/");
    },
    onClickRight() {

    },
    formmatTime(time) {
      const string = JSON.stringify(time)
      const monthDay = string.split('T')[0].slice(6)
      const timeStr = string.split('.')[0].split('T')[1].slice(0,5)
      return monthDay +' '+ timeStr
    },
    pushNewHistory() {
      this.newHistory.createdTime= this.formmatTime(new Date())
      this.history.unshift(this.newHistory)
      this.showInput = false
      this.doneList.unshift(this.newHistory)
      this.setSessionData()
      this.setAllScore()
      this.setThisScore()
    },
    setSessionData() {
      const obj = {
        userList: this.players,
        history: this.history,
        gameInfo: this.gameInfo,
        doneList: this.doneList,
      }
      localStorage.setItem('ID-'+this.$route.query.id, JSON.stringify(obj))
    },
    openDialog() {
      this.newHistory = {
        state: 1, // 0为删除状态数据
        userList: this.players.map(user => {
          return {
            name: user.name,
            score: ''
          }
        })
      }

      this.showInput = true
    },
    setThisScore() {
      this.players = this.players.map(item => {
        item.currentScore = 0
        return item
      })

      let rushFlag = false;
      let rushPlayers = [];

      this.doneList.forEach((item) => {
        !!item.state && item.userList.forEach((person,personIndex) => {
          if(!this.players[personIndex].currentScore) {
            this.players[personIndex]['currentScore'] = 0
          }
          const tmp = this.players[personIndex].currentScore + Number(person.score);
          if (tmp >= 100) {
            rushPlayers.push(this.players[personIndex].name)
            rushFlag = true;
          }
          this.players[personIndex].currentScore = tmp;
        })
      })

      if (rushFlag) {
        this.players.forEach((player, playerIndex) => {
          this.players[playerIndex].currentScore = this.players[playerIndex].currentScore % 10;
        })

        const doneHistory = {
          state: 1,
          createdTime: this.formmatTime(new Date()),
          userList: this.players.map(user => {
            return {
              name: user.name,
              score: user.currentScore
            }
          })
        };
        this.doneList = [];
        this.doneList.unshift(doneHistory);
        rushPlayers = Array.from(new Set(rushPlayers));
        this.rushUser = rushPlayers.join(',');
        let audio = new Audio(require('../../assets/dingdong.mp3'))
        audio.play();
        this.rushLine = true;
      }

      this.setSessionData()
    }
  },
  created() {
    const id = this.$route.query.id
    const key = 'ID-'+id;
    if(localStorage[key]) {
      const clientData = JSON.parse(localStorage[key])
      this.players = clientData.userList
      this.gameInfo = clientData.gameInfo
      this.newHistory = {
        state: 1, // 0为删除状态数据
        userList: this.players.map(user => {
          return {
            name: user.name,
            score: user.scope
          }
        })
      }

      this.history = clientData.history || []
      this.setAllScore()
    }
  },
}
</script>

<style scoped>
.van-grid {
  display: flex;
}
.settlement-wrap{
  height: 100%;
  padding-bottom: 84px;
}
.btn-list{
  width: 100%;
  position: fixed;
  background: transparent;
  z-index: 100;
  bottom: 0;
  margin-bottom: 20px;
}
.btn-list-model{
  position: fixed;
  bottom: 0;
  margin-bottom: 20px;
  margin-left: 20px;
}
.person-list{
  margin: 20px
}
.person-list .name{
  margin-bottom: 10px;
}
.btn-list button,.btn-list-model button{
  width: 150px;
}
.input-score-model{
  padding: 0 20px;
  background: rgb(0, 10, 12, .7) !important;
  color: #fff !important;
}
.input-score-model /deep/  .van-field__body{
  margin-top: 10px;
  border: 1px solid #ccc;
  width: 60px;
  background: #fff;
}
.input-score-model /deep/  .van-field__body input{
  text-align: center;
}
.row-wrap{
  margin-top: 60px;
}
.input-score-model .van-field{
  padding: 0;
  background: transparent;
}
.history-table{
  background: #fff;
  margin: 0 10px;
  padding: 16px 0;
}
.history-table .title{
  font-size: 20px;
  font-weight: 600;
}
.input-score{
  padding: 10px;
  margin: 0 10px;
  /* width: 80%; */
  border: 1px solid rgb(32, 97, 55);
}
.history-table /deep/ .grid-wrap{
  margin: 8px;
  padding-bottom: 16px;
  border: 2px solid rgb(32, 97, 55);
  background-color: rgb(174, 141, 100) !important
}
.deep-background{
  background-color: rgb(174, 141, 100) !important;
}
.deep-background /deep/ .van-grid-item__content{
  background-color: rgb(174, 141, 100) !important;
  color: #000;
  font-weight: 600;
}
</style>
<style>
.op .van-grid-item__text{
  font-size: 14px;
  color: red;
  font-weight: 600;
}
.opb .van-grid-item__text{
  font-size: 14px;
  color: blue;
  font-weight: 600;
}
.disabled-see .van-grid-item__text{
  opacity: .8;
  font-size: 18px;
  text-decoration:line-through
}
.see .van-grid-item__text{
  font-size: 18px;
}
.rush-line{
  position: absolute;
  left: 45%;
  top: 30%;
  text-align: center;
  width:200px;
  height:100px;
  margin-left:-120px;
  margin-top:-50px;
}
.rush-line-text{
  position: absolute;
  left: 50%;
  top: 30%;
  text-align: center;
  width:200px;
  height:100px;
  margin-left:-120px;
  margin-top:210px;
  color: orange;
}
</style>
