<template>
  <div class="account">
    <van-nav-bar
      title="结算"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="av-card-set">
        每张：¥
        <van-field v-model="money"></van-field>元
      </div>
      <div class="zongjie">
        <van-grid :column-num="5" :border="false" class="header-grid">
          <van-grid-item text="玩家" />
          <van-grid-item text="牌数" />
          <van-grid-item text="四舍五入" />
          <van-grid-item text="结果" />
          <van-grid-item text="¥ 金额" />
        </van-grid>
        <van-grid :column-num="5" :border="false" v-for="(user,index) in players" :key="user+index">
          <van-grid-item :text="user.name" />
          <van-grid-item :text="String(user.allScore)" />
          <van-grid-item :text="String(decimal(user.allScore))" />
          <van-grid-item :text="String(user.result)" />
          <van-grid-item :text="String(user.result * Number(money))" :class="user.result * money <  0 ? 'redColor': ''"   />
        </van-grid>
      </div>
      <div class="desc">
        <div class="title">计算方式</div>
        <div class="bg-white">
          <p>
            首先将总牌数进行四舍五入计算
          </p>
          <p>玩家1得分=（玩家2-玩家1）+（玩家3-玩家1）+（玩家4-玩家1）</p>
          <p>玩家2得分=（玩家1-玩家2）+（玩家3-玩家2）+（玩家4-玩家2）</p>
          <p>玩家3得分=（玩家1-玩家3）+（玩家2-玩家3）+（玩家4-玩家3）</p>
          <p>玩家4得分=（玩家1-玩家4）+（玩家2-玩家4）+（玩家3-玩家4）</p>
          <p>以此类推...</p>
        </div>
      </div>
    </div>
  </div>


</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      money:10,
      id: null,
      allData: null
    }
  },
  methods: {
    onClickLeft() {
      this.$router.replace({
        name: 'Settlement',
        query: {
          id: this.$route.query.id
        }
      })
    },
    decimal(num) {
      const gws = num % 10;
      if (gws < 5) {
        return num - gws;
      } else {
        return num + (10 - gws);
      }
    }
  },
  components: {

  },
  created() {
    this.id = this.$route.query.id
    if(this.id) {
      this.allData = localStorage.getItem('ID-'+this.id) ? JSON.parse(localStorage.getItem('ID-'+this.id)) : []
    }
    const userList = this.allData.userList
    const resultList = userList.map(user => this.decimal(user.allScore) * 1)
    this.players = userList.map((item,idx) => {
      let allResult = 0
      resultList.forEach((result,index) => {
        if(index !== idx) {
          allResult += result
        }
      })
      return {
        ...item,
        result: allResult - resultList[idx]*(userList.length - 1)
      }
    })
  }
}
</script>

<style>
.content{
  padding: 0 10px;
}
.av-card-set{
  margin: 30px 0;
  display: flex;
  align-items: center;
}
.av-card-set .van-field{
  display: inline-block;
  width: 80px;
  margin: 0 15px;
}
.redColor .van-grid-item__text{
  color: red;
}
.desc{
  font-size: 12px;
}
.desc .title{
  margin: 20px 0;
  font-size: 18px;
  font-weight: 600;
}
.bg-white{
  background: #fff;
  padding: 5px 10px;
  border: 2px solid rgb(32, 97, 55);
}
.header-grid{
  border-bottom: 2px solid rgb(32, 97, 55);;
}
.zongjie .header-grid .van-grid-item__content{
  padding-bottom: 3px;
}
.zongjie .van-grid-item__content{
  padding: 5px
}
</style>
